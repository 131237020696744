import stCss from '../../st-css';
import {variant, styleProps, marginShorthandProps, paddingShorthandProps, sizeShorthandProps, textShorthandProps} from 'st-css';

export const Panel = stCss.canonize('div')(({ gap, alignItems }) => ({
    columnGap: gap || ['32px', '48px', '64px'],
    rowGap: gap || ['32px', '48px'],
    alignItems: alignItems || 'center',
    display: 'grid',
    width: '100%',
    zIndex: 1
}), 
variant('layout', (props) => ({ 
    default: {
        gridTemplateColumns: props.split ? '1fr 1fr' : 'auto 1fr',
        
    },
    flip: {
        gridTemplateColumns: props.split ? '1fr 1fr' : '1fr auto',
        '> *:first-child': {
            order: 1
        }
    },
    stack: {
        rowTemplateColumns: '1fr 1fr',
        justifyItems: 'center',
    },
    'stack-flip': {
        rowTemplateColumns: '1fr 1fr',
        justifyItems: 'center',
        '> *:first-child': {
            order: 1
        }
    },
    overlay: {
        gridTemplateColumns: '1fr',
        position: 'relative',
        '> img': {
            zIndex: -1,
            position: 'absolute'
        }
    }
}), 'default'),
    styleProps(marginShorthandProps, paddingShorthandProps, sizeShorthandProps, textShorthandProps)
);