import { useContext } from 'react';
import { Section } from '../components/section/section';
import { ConstrainedImage } from '../components/image/constrained-image';
import { FullWidthImage } from '../components/image/full-width-image';
import { Link } from '../components/link/link';
import { Content } from '../components/content/content';
import { Panel } from '../components/panel/panel';
import { Subtitle } from '../components/subtitle/subtitle';
import { Text } from '../components/text/text';
import { Button } from '../components/button/button';
import { Container } from '../components/container/container';
import { ServiceCards } from '../components/service-cards/service-cards';
import { Grid } from '../components/grid/grid';
import { Icon } from '../components/icon/icon';
import { LayoutContext } from '../components/layout/layout';
import { Overlay } from '../components/overlay/overlay';
import { ScrollIndicator } from '../components/scroll-indicator/scroll-indicator';
import { Meta } from '../components/meta/meta';

const Home = () => {
  const { navOpen } = useContext(LayoutContext);
  return (
    <>
      <Meta title="Dr. Todd's Wellness" />
      <Section layout="fullscreen" css={{ backgroundColor: '#000' }}>
        <FullWidthImage name="strong-woman-deadlifting" type="bg" css={{ opacity: 0.4 }} />
        <Overlay open={navOpen} />
        <Content
          h={1}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <ConstrainedImage
            name="chesterfield-family-chiropractic-center-logo"
            w={[0.8, 0.5]}
            minw={280}
            maxw={800}
            hide={[navOpen, navOpen, 0]}
          />
          <Content
            hide={[0, navOpen, 0]}
            css={{
              zIndex: 1,
              width: ['100%', '50%'],
              maxWidth: ['500px', '400px'],
              px: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: ['absolute', 'relative'],
              bottom: ['32px', '-32px'],
            }}
          >
            <Grid layout="columns" template="24px max-content" gap="8px">
              <Icon icon="phone" color="#fff" height="24px" width="24px" />
              <Link color="#fff" size="22px" pb={0} variant="unstyled" to="tel:586-948-7246">
                586-948-7246
              </Link>
            </Grid>
            <Button variant="glass" w={122} to="#book-appointment">
              Contact
            </Button>
          </Content>
        </Content>
        <Content
          hide={[1, 0]}
          css={{
            position: 'absolute',
            bottom: 0,
            height: '80px',
            color: '#fff',
            backgroundColor: 'rgba(0, 133, 255, .2)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Link
            to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '2px solid #ffffff80',
              borderRight: '1px solid #ffffff80',
            }}
          >
            Chesterfield Location | Get Directions
          </Link>
          <Link
            to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '1px solid #ffffff80',
              borderRight: '2px solid #ffffff80',
            }}
          >
            Pontiac Location | Get Directions
          </Link>
        </Content>
        <Content
          hide={[1, 0]}
          css={{
            zIndex: 1,
            position: 'absolute',
            left: '10%',
            bottom: '128px',
            display: 'grid',
            gridTemplateColumns: 'max-content max-content',
            columnGap: '48px',
          }}
        >
          <Link to="https://www.facebook.com/DrToddsWellness">
            <Icon icon="facebook-logo" w="32px" h="32px" />
          </Link>
          <Link to="https://www.instagram.com/drtoddswellness">
            <Icon icon="instagram-logo" w="32px" h="32px" />
          </Link>
        </Content>
        <ScrollIndicator hide={navOpen} />
      </Section>
      <Section>
        <Container maxw={980}>
          <Panel layout={['stack', 'default']} split gap="48px">
            <ConstrainedImage name="chiropractor-performing-adjustment" hide={[1, 0]} />
            <Content>
              <Subtitle>Chiropractic Care Tailored to You</Subtitle>
              <Text>
                Dr. Todd understands that a lifetime of good health cannot be achieved through a &apos;one size fits
                all&apos; approach. The best healthcare comes from caring and committed physicians who have time to get
                to know their patients and get their patients what they really need.
              </Text>
              <Button to="#book-appointment">Book an Appointment</Button>
            </Content>
          </Panel>
        </Container>
      </Section>
      <Section>
        <ServiceCards />
      </Section>
    </>
  );
};

export default Home;
