import stCss from '../../st-css';
import {styleProps, textShorthandProps} from 'st-css';
import { spacingShorthandProps, visibilityShorthandProps } from 'st-css';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';

export const ScrollIndicatorStyled = stCss.canonize('div')({
    h: 125,
    w: 15,
    position: 'absolute',
    overflow: 'hidden',
    zIndex: 1,
    right: ['32px', '15%'],
    bottom: ['120px', '15%'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
}, styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps));

export const ScrollIndicator = (props) => {
    return (
        <ScrollIndicatorStyled {...props}>
            <Text color="#fff" css={{transform: 'rotate(-90deg)', transformOrigin: 'left', w: 80, pt: 13, pb: 0, textTransform: 'uppercase'}}>Scroll Down</Text>
            <Icon icon="arrows" w="14px" height="22px"/>
        </ScrollIndicatorStyled>
    )
}